import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Team from '@solid-ui-blocks/Teams/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import theme from './_theme'
import styles from './_styles'

const AboutUs01 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Container variant='full' sx={styles.bhwhite} >
      <Divider space='3' />
        <Container variant='wide' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse  />
        </Container>
        <Divider space='3' />
      </Container>
        <Hero content={content['empowered']} reverse />
        <Divider space='4' />
        <FeatureTwo content={content['never-miss']} />
        <Divider space='4' />
        <Hero content={content['get-your']} reverse />
        <Divider space='4' />
        <FeatureTwo  content={content['all-in']} />
        <Divider space='4' />
        <Hero content={content['quality-leads']} reverse />
        <Divider space='4' />
        <FeatureTwo  content={content['automate-your']} />
        <Divider space='4' />
        <Hero content={content['dynamic-lead']} reverse />
        <Divider space='4' />
        <FeatureTwo  content={content['turn-leads']}  />
      {/* <Divider space='5' /> */}
      {/* <Content content={content['content-one']} /> */}
      {/* <Divider space='5' /> */}
      {/* <Team content={content['team']} /> */}
      {/* <Container variant='wide' sx={styles.contentTwoContainer}>
        <Content content={content['content-two']} />
      </Container> */}
      {/* <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageAboutUs01BlockContent {
    allBlockContent(
      filter: { page: { in: ["innerpage/about-us-01", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default AboutUs01
